.reviews-carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 40px 20px;
  background-color: #e7e4e1;
}

.review-container {
  position: relative;
  max-width: 1300px;
  width: 100%;
  padding: 60px 20px 30px;;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.quote-image {
  position: absolute;
  top: 15px;
  left: 200px;
  width: 60px;
  height: 60px;
  z-index: -1; 
  opacity: 0.7;
}

.review-text {
  font-family: 'Mukta Mahee', sans-serif;
  font-size: 32px;
  color: #382e2d;
  margin:0px 0;
  text-align: justify;
  text-align-last: center;
  line-height: 1.3;
  padding: 0 50px;
  z-index: 2;
}

.review-author {
  font-family: 'Mukta Mahee', sans-serif;
  font-size: 24px;
  color: #382e2d;
  text-align: center;
  margin: 15px 0;
}

.review-rects {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.rect {
  height: 8px;
  width: 60px;
  background-color: #bbb;
  margin: 5px 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 3px;
}

.rect.active {
  background-color: #784e3b;
  width: 80px;
}
.arrow-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  user-select: none;
  z-index: 3;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.arrow-nav:hover {
  background: rgba(120, 78, 59, 0.7);
  color: white;
}

.arrow-left {
  left: 10px;
}

.arrow-right {
  right: 10px;
}
@media (min-width: 1680px) {
  .review-text {
    padding: 0 200px;
  }
  .arrow-left {
    left: 150px;
  }
  
  .arrow-right {
    right: 150px;
  }
}
@media (max-width: 768px) {
  .reviews-carousel {
      padding: 10px 15px;
  }
  
  .review-container {
      padding: 20px 10px;
  }
  
  .quote-image {
      width: 50px;
      height: 40px;
      top: 5px;
      left: 125px;
  }
  
  .review-text {
      width: 125vw;
      font-size: 20px;
      padding: 0 150px;
  }
  
  .review-author {
      font-size: 18px;
      margin: 10px 0;
  }
  
  .rect {
      width: 40px;
      height: 18px;
      margin: 5px 6px;
  }
  
  .rect.active {
      width: 50px;
  }
  .arrow-nav {
    width: 30px;
    height: 30px;
    font-size: 18px;
  }
  .arrow-left {
    left: 5px;
  }
  .arrow-right {
    right: 5px;
  }
}

@media (max-width: 480px) {
  .quote-image {
    width: 40px;
    height: 30px;
    /* top: 11px; */
}
  .review-text {
    width: 150vw;
    font-size: 18px;
    padding: 0 150px;
  }
  
  .review-author {
      font-size: 16px;
  }
  
  .rect {
    height: 24px;
      width: 30px;
      margin: 5px 4px;
  }
  
  .rect.active {
      width: 40px;
  }
  .arrow-nav {
    width: 25px;
    height: 25px;
    font-size: 16px;
  }
  .arrow-left {
    left: -6px;
  }
  .arrow-right {
    right: -6px;
  }
}