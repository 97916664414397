.home-header {
  position: sticky;
  top: 0;
  width: 100%;
  height: 40px;
  background-color: rgb(59,46,46);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.logo {
  height: 20px;
  width: auto;
  margin-left: 0;
  transition: opacity 0.3s ease-in-out;
}

.scrolled {
  opacity: 1; 
}

.hamburger {
  display: none;
  cursor: pointer;
  background: none;
  border: none;
  padding: 10px;
}

.hamburger span {
  display: block;
  width: 25px;
  height: 2px;
  background-color: white;
  margin: 5px 0;
  transition: 0.3s;
}

.header-nav {
  display: flex;
}

.nav-button {
  color: white;
  font-family: 'Mukta Mahee', sans-serif;
  font-size: 15px;
  letter-spacing: 1px;
  padding: 15px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-button:hover {
  color: black;
}
@media (min-width: 2100px) {
  .logo {
    height: 30px;
  }
  .home-header {
    height: 60px;
  }
  .nav-button {
    font-size: 20px;
  }
}
@media (max-width: 1000px) {
  .hamburger {
    display: block;
  }
  
  .header-nav {
    position: fixed;
    top: 38px;
    left: -100%;
    flex-direction: column;
    background-color: rgb(59,46,46);
    width: 100%;
    padding: 20px 0;
    transition: 0.3s;
  }
  
  .header-nav.active {
    left: 0;
  }
  
  .nav-button {
    width: 100%;
    text-align: center;
    padding: 15px;
  }
}