  .projects-content {
    margin: 0 auto;
    padding-top: 10px;
    max-width: 100vw; 
  }
  .stacked-image,
.double-image {
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.stacked-image img {
  width: 100%;
  height: auto;
  max-height: 70vh;
  object-fit: contain;
}
.double-image {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.double-image img {
  width: calc(50% - 5px);
  height: 20vh;
  max-height: 65vh;
  object-fit: cover;
}

  .middle-section {
    display: grid;
    grid-template-columns: 700px 1fr;
    gap: 10px;
    margin-bottom: 10px;
    height: 1300px;
  }

  .tall-image {
    width: 100%; 
    height: 1300px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  
  .large-image,
  .small-image,
  .full-width-image,
  .three-column img,
  .top-right-image,
  .bottom-right-images img {
    aspect-ratio: 3/4;
  }
  
  .tall-image {
    aspect-ratio: 9/16;
  }
  
  .full-width-image {
    aspect-ratio: 16/9;
  }
  
  .three-column img:first-child {
    aspect-ratio: 9/16;
  }
  
  .right-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 1300px;
    width: 100%;
    overflow: hidden;
  }

  .top-right-image {
    height: 500px;
    width: 100%;
    min-height: 500px;
    max-height: 500px; 
  }

  .bottom-right-images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 900px;
    gap: 10px;
  }
  
  .top-row {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .large-image {
    flex: 3;
    height: 700px;
  }
  
  .small-image {
    flex: 1;
    height: 700px;
  }
  
  .full-width-image {
    width: 100%;
    height: 1000px;
    margin-bottom: 10px;
  }
  
  .three-column {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    width: 100%;
    height: 800px;
  }

  .three-column img:first-child {
    flex: 0.36; 
    height: 100%; 
  }
  
  .three-column img:nth-child(2) {
    flex: 0.32; 
    height: 100%; 
  }
  
  .three-column img:nth-child(3) {
    flex: 0.32;
    height: 100%;  
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

.middle-section,
.top-row,
.three-column,
.bottom-right-images {
    grid-template-columns: 1fr;
    height: auto;
}

.large-image,
.small-image,
.full-width-image,
.three-column img,
.top-right-image,
.bottom-right-images img {
    height: auto;
    min-height: unset;
    max-height: unset;
}
@media (min-width: 2100px) {
  .middle-section {
    display: grid;
    grid-template-columns: 900px 1fr;
    gap: 15px;
    margin-bottom: 15px;
    height: 1950px; 
  }

  .tall-image {
    height: 1950px; 
    aspect-ratio: 9/16;
  }

  .right-column {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 1950px;
    width: 100%;
    overflow: hidden;
  }

  .top-right-image {
    height: 750px; 
    width: 100%;
    min-height: 750px;
    max-height: 750px;
    aspect-ratio: 3/4;
  }

  .bottom-right-images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 1185px; 
    gap: 15px;
  }

  .bottom-right-images img {
    height: 1200px;
  }

  .top-row {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-bottom: 15px;
    height: 1050px; 
  }

  .large-image {
    flex: 3;
    height: 1050px; 
    aspect-ratio: 3/4;
  }

  .small-image {
    flex: 1;
    height: 1050px; 
    aspect-ratio: 3/4;
  }

  .full-width-image {
    width: 100%;
    height: 1500px; 
    margin-bottom: 15px;
    aspect-ratio: 16/9;
  }

  .three-column {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-bottom: 15px;
    width: 100%;
    height: 1000px; 
  }

  .three-column img:first-child {
    flex: 0.36;
    height: 100%;
    aspect-ratio: 9/16;
  }

  .three-column img:nth-child(2) {
    flex: 0.32;
    height: 100%;
    aspect-ratio: 3/4;
  }

  .three-column img:nth-child(3) {
    flex: 0.32;
    height: 100%;
    aspect-ratio: 3/4;
  }
}
@media (min-width: 1920px) and (max-width: 2100px) {
  .middle-section {
      display: grid;
      grid-template-columns: 700px 1fr;
      gap: 10px;
      margin-bottom: 10px;
      height: 1300px;
  }

  .tall-image {
      height: 1300px;
  }

  .right-column {
      height: 1300px;
  }

  .top-right-image {
      height: 500px;
  }

  .bottom-right-images {
      height: 900px;
      grid-template-columns: repeat(2, 1fr);
  }

  .top-row {
      flex-direction: row;
      height: 700px;
  }

  .large-image,
  .small-image {
      height: 700px;
  }

  .full-width-image {
      height: 1000px;
  }

  .three-column {
      height: 800px;
      flex-direction: row;
  }
}



