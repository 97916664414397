.contact-banner {
    width: 100vw;
    height: 300px;
    overflow: hidden;
  }
  .success-popup {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: #4CAF50;
    color: white;
    padding: 15px 30px;
    border-radius: 5px;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0,0,0,0.2);
    animation: slideDown 0.3s ease-out;
  }
  
  @keyframes slideDown {
    from { top: -50px; }
    to { top: 20px; }
  } 
  .contact-banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .contact-content {
    display: flex;
    justify-content: space-between;
    padding: 100px 10px;
    gap: 200px;
    max-width: 1600px;
    margin: 0 auto;
    
  }
  
  .contact-info-container {
    flex: 0 1 45%;
  }
  
  .contact-form-container {
    flex: 1;
  }
  
  .contact-info h1 {
    font-family: 'Mukta Mahee', sans-serif;
    font-size: 42px;
    color: #3b2e2e;
  }
  
  .contact-info p {
    font-size: 24px;
    line-height: 1.6;
    width: 100%;
    color: #3b2e2e;
    text-align: justify;
    text-align-last: left;
  }
  
  .spacer-50 {
    height: 30px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 10px;
    font-family: 'Mukta Mahee', sans-serif;
    color: #3b2e2e;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  .form-group textarea {
    resize: vertical;
  }
  
  .error {
    border-color: #ff0000 !important;
  }
  
  .error-message {
    color: #ff0000;
    font-size: 14px;
    margin-top: 5px;
    display: block;
  }
  
  .submit-button {
    font-family: 'Mukta Mahee', sans-serif;
    font-size: 20px;
    background-color: rgb(59,46,46);
    color: white;
    padding: 15px 30px;
    border-radius: 2px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    transform: scale(1);
  }
  
  .submit-button:hover {
    background-color: #2a2221;
    transform: scale(1.05);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  @media (min-width: 2100px) {
    .contact-content {
      max-width: 1920px;
    }
    .contact-info-container h1 {
      font-size: 50px;
    }
    .contact-info-container p {
      font-size: 35px;
    }
    .form-group label {
      font-size: 28px;
      margin-bottom: 15px;
    }
  
    .form-group input,
    .form-group textarea {
      font-size: 24px;
      padding: 20px;
    }
  
    .submit-button {
      font-size: 28px;
      padding: 20px 40px;
    }
  
    .error-message {
      font-size: 20px;
    }
  
    /* Adjust textarea height */
    .form-group textarea {
      height: 400px;
    }
  
    /* Bigger spacing */
    .spacer-50 {
      height: 50px;
    }
  }
  @media (max-width: 1024px) {
    .contact-content {
      flex-direction: column;
      padding: 50px 20px;
      gap: 50px;
    }
    .submit-button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto; 
      width: fit-content;
    }
    .contact-info-container,
    .contact-form-container {
      width: 100%;
      padding: 0;
    }
  
    .contact-info h1 {
      font-size: 32px;
    }
  }