@font-face {
  font-family: 'Roserita';
  src: url('/public/fonts/Roseritta.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  scrollbar-color: rgb(59,46,46) rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.scroll-indicator {
  position: absolute;
  bottom: 10%;
  left: 50%;
  opacity: 1;
  transition: opacity 0.5s ease;
  transform: translateX(-50%);
  z-index: 3;
  animation: bounce 2s infinite;
  cursor: default;
  width: 40px; 
  height: 40px; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  40% {
    transform: translateX(-50%) translateY(-30px);
  }
  60% {
    transform: translateX(-50%) translateY(-15px);
  }
}

.chevron-icon {
  font-size: 40px;
  color: rgb(59,46,46);
  transition: all 0.3s ease;
}


.home {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.homepage-content {
  flex: 1; 
}


.nav-button {
  text-decoration: none;
  color: white;
  font-family: 'Mukta Mahee', sans-serif;
  font-weight: regular;
  font-size: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 17px;
  transition: color 0.3s ease;
}

.nav-button:hover {
  color: black;
}

.video-wrapper {
  position: relative;
  height: 100vh;
  width: 100vw;
}
.video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: 0;
}
.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.loader {
  width: 50px;
  height: 50px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3b2e2e;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

@keyframes spin {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}

.video-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.placeholder-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.overlay-image {
  position: absolute;
  display: relative;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  max-width: 133vw; 
  max-height: 133vh;
  object-fit: contain;
  z-index: 2;
  transition: all 0.3s ease;
}

.text-section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2vw 0;
  margin: 2rem 0;
}

.text-section h2 {
  font-family: kozuka-mincho-pr6n, serif;
  font-weight: 500;
  font-size: 45px;
  line-height: 1.2;
  padding: 0 5%;
  margin-bottom: 2rem;
  letter-spacing: 0;
  word-spacing: -6px;
  word-wrap: break-word;
}
.text-section h2 span {
  font-size: inherit !important;
}
.text-section h2 br + span {
  font-size: 65px;
  margin-left: -45px;
}

.text-blocks {
  letter-spacing: 0.01px;
  display: flex;
  width: 74%;
  justify-content: center;
  text-align: justify;
  text-align-last: left;
}
.text-blocks-second-row {
  letter-spacing: 0.01px;
  display: flex;
  width: 74%;
  justify-content: center;
  text-align: justify;
  text-align-last: left;
}
.text-block {
  width: 45%;
  padding: 20px;
}

.text-block1 {
  width: 45%;
  padding: 20px;
  margin-top: -9.7px;
}

.text-block2 {
  width: 45%;
  padding: 20px;
  margin-top: -31px;
}

.text-block p, 
.text-block1 p, 
.text-block2 p {
  flex: 1 1 300px;
  padding: 0rem;
  font-size: 17px;
  line-height: 1.2;
  font-family: 'Mukta Mahee', sans-serif;
}

.span1 {
  width: 50%;
  color: rgb(120, 70, 59);
}

.span2 {
  display: block;
  width: 100%;
  color: rgb(120, 70, 59);
}

.homepage-content {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.reviews-block {
  width: 100%;
    background-color: #e7e4e1;
    padding: 60px 0;
}

.reviews-title {
  font-family: kozuka-mincho-pr6n, serif;
  font-size: 45px;
  font-weight: bold;
  color: rgb(59,46,46);
  text-align: center;
  margin: 0 0 0px 0;
}

@media (min-width: 2100px) {
  .text-block p, 
  .text-block1 p, 
  .text-block2 p {
    flex: 1 1 300px;
    padding: 1rem;
    font-size: 24px;
    line-height: 1.2;
    font-family: 'Mukta Mahee', sans-serif;
  }
  .text-block1 {
    margin-top: -30px;
  }
  .text-block2 {
    margin-top: -60px;
  }
  .text-section h2 {
    font-size: 65px;
  }
  .scroll-indicator {
    height: 100px;
    width: 100px;
  }
  .chevron-icon {
    font-size: 100px;
    color: rgb(59,46,46);
    transition: all 0.3s ease;
  }
}

@media (max-width: 933px) {
.text-section {
  height: auto;
  padding: 40px 20px;
  margin-top: 20px;
}

.text-section h2 {
  font-size: 32px;
  line-height: 1.2;
  word-spacing: normal;
}

.text-blocks,
.text-blocks-second-row {
  flex-direction: column;
  width: 100%;
}

.text-block,
.text-block1,
.text-block2 {
  width: 100%;
  padding: 10px;
  margin-top: 0;
}

.text-block p,
.text-block1 p,
.text-block2 p {
  font-size: 20px;
}
}
@media (max-width: 768px) {
  .scroll-indicator {
    height: 75px;
    width: 75px;
    bottom: 15%;
  }
  .chevron-icon {
    font-size: 75px;
  }
  .overlay-image {
    max-width: 200vw; 
    max-height: 200vh
  }

  .reviews-block {
    padding: 40px 0;
  }

  .reviews-title {
    margin-bottom: 20px;
  }

  .projecten-title {
    font-size: 28px;
    margin-top: 30px;
  }
}

@media (max-width: 480px) {
  .text-section h2 {
    font-size: 28px;
  }
  .projecten-title,
  .reviews-title {
    font-size: 24px;
  }
}