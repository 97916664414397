.slider-container {
  background-color: rgb(59,46,46);
  position: relative;
  padding: 50px 0 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}
.slider-container h1 {
  margin-bottom: 50px;
  font-family: kozuka-mincho-pr6n, serif;
}
  
#image-track {
  display: flex;
  gap: 4vmin;
  user-select: none;
  will-change: transform;
  transition: transform 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);
  padding: 0 1%;
  position: relative;
  left: 0;
}

.image-scrollbar {
  position: relative;
  width: 98%;
  height: 15px;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border-radius: 5px;
  flex-shrink: 0;
}

#image-track > .image {
    width: 438px; 
    height: 605px; 
    flex-shrink: 0; 
    min-width: 438px; 
    object-position: 100% center;
    cursor: pointer;
    transition: object-position 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
  }
  /* @media (min-width: 769px) {
    .slider-container {
      touch-action: pan-x; 
    }
    #image-track {
      pointer-events: auto !important;
    }
  } */
@media (max-width: 768px) {
  .image-scrollbar {
      height: 30px;
    } 
    #image-track > .image {
      width: 300px; 
      height: 450px;  
      flex-shrink: 0; 
      min-width: 300px; 
      object-position: 100% center;
      cursor: pointer;
      transition: object-position 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
    }
}