.new-section {
  padding: 0;
}

.inspiration-section {
  background-color: rgb(59,46,46);
  min-height: 20vh; 
  padding: 4vw 3vw; 
  position: relative;
}

.inspiration-content {
  width: 90%; 
  max-width: 1600px; 
  margin: 0 auto;
}

.inspiration-title {
  color: white;
  font-family: kozuka-mincho-pr6n, serif;
  font-size: clamp(32px, 4vw, 56px); 
  margin: 2rem 0;
  text-align: center; 
  display: block; 
  width: 100%;
}


.inspiration-subtitle {
  color: white;
  font-family: 'Mukta Mahee', sans-serif;
  font-size: 28px;
  margin: 2rem 0;
  line-height: 1.5;
  display: flex;
  justify-content: center; 
  text-align-last: center;
}

.contact-boxes {
  display: flex;
  font-family: 'Mukta Mahee', sans-serif;
  font-size: 23px;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3vw; 
  padding: 2rem 0;
  width: 100%;
  margin: 0 auto;
  transition: all 0.3s ease;
    transform: scale(1);
}

.contact-box {
  border: 2px solid white;
  padding: 15px;
  color: white;
  text-decoration: none;
  flex: 1 1 300px; 
  max-width: 650px;
  text-align: center;
  transition: all 0.3s ease;
  letter-spacing: 0.75px;
  box-sizing: border-box;
  margin: 1vw;
}

.instagram-image-container {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.instagram-icon-overlay {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 2;
}

.instagram-icon-overlay svg {
  color: #382e2d;
  font-size: 24px;
}


.instagram-section {
  padding: 5vw 0;
  text-align: center;
}

.instagram-title {
  font-size: clamp(28px, 3vw, 48px);
  font-family: kozuka-mincho-pr6n, serif;
  color: rgb(59,46,46);
  margin: 2rem auto;
  padding: 0 5%;
  line-height: 1.2;
  max-width: 1600px;
}

.instagram-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  max-width: 90%;
  min-height: 500px;
  margin: 0 auto 30px;
  padding: 0 20px;
}

.instagram-grid img {
  width: 100%;
  height: 100%;
  max-height: 600px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.instagram-button {
  font-family: 'Mukta Mahee', sans-serif;
  font-size: 20px;
  background-color: rgb(59,46,46);
  color: white;
  padding: 15px 30px;
  border-radius: 2px;
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 auto;
  cursor: pointer;
  transition: all 0.3s ease;
  transform: scale(1);
}



.instagram-icon {
  font-size: 30px;
}
@media (min-width: 789px) {
  .instagram-button:hover {
    background-color: #2a2221;
    transform: scale(1.05);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  .instagram-image-container:hover .instagram-icon-overlay {
    transform: scale(1.1);
    background-color: rgba(255, 255, 255, 1);
  }
  
  .instagram-image-container:hover img {
    transform: scale(1.03);
  }
  .contact-box:hover {
    color: #2a2221;
    background-color: white;
    transform: scale(1.05);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
}
@media (min-width: 2140px) {
  .instagram-button {
    font-size: 28px;
  }
}
@media (min-width: 1921px) {
  .instagram-grid {
    max-width: 1600px;
    gap: 50px;
  }
}
@media (min-width: 1920px) {
  .contact-box {
    padding: 27px;
    font-size: 1.5rem;
    width: 450px;
  }
}
@media (max-width: 1450px) {
  .instagram-grid {
    gap: 30px;
  }
}

@media (max-width: 1024px) {
  .instagram-grid {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .instagram-grid img {
    max-height: 400px;
  }
}

@media (max-width: 940px) {
  .instagram-grid {
    min-width: 300px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    max-width: 95%;
  }
  
  .instagram-grid img {
    max-height: 300px;
  }
}
@media (max-width: 768px) {
  .contact-box {
    flex-basis: 80%;
  } 
  .inspiration-subtitle {
    font-size: 22px;
  }
}
@media (max-width: 650px) {
  .inspiration-section {
    min-height: auto;
    padding: 2rem 1rem;
  }
}
@media (max-width: 480px) {
  .instagram-grid {
    grid-template-columns: 1fr;
    max-width: 100%;
    padding: 0 10px;
  }
  
  .instagram-grid img {
    max-height: 400px;
  }
}

