.privacy-page {
    background-color: white;
  }
  
  .privacy-container {
    padding: 50px;
    padding-top: 25px;
    max-width: 99%vw;
    margin: 0 auto;
    line-height: 1.3;
    color: #333;
    font-family: 'Mukta Mahee', sans-serif;
  }
  
  h1 {
    font-size: 50px;
    color: #3b2e2e;
  }
  
  h2 {
    font-size: 32px;
    color: #3b2e2e;
  }
  
  .brown-title {
    color: #3b2e2e !important;
  }
  
  .section-spacer {
    height: 50px;
  }
  
  p {
    font-size: 24px;
    white-space: pre-line;
    text-align: justify;
    text-align-last: left;
  }
  
  @media (max-width: 768px) {
    .privacy-container {
      padding: 30px 20px;
    }
  
    h1 {
      font-size: 32px;
      margin-bottom: 30px;
    }
  
    h2 {
      font-size: 24px;
    }
  
    p {
      font-size: 14px;
    }
  
    .section-spacer {
      height: 30px;
    }
  }
  
  @media (max-width: 480px) {
    h1 {
      font-size: 28px;
    }
  
    h2 {
      font-size: 20px;
    }
  }